module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://blog.dozeninvestments.com/graphql","verbose":true,"develop":{"hardCacheMediaFiles":true,"nodeUpdateInterval":2000000,"hardCacheData":false},"production":{"hardCacheMediaFiles":true,"allow404Images":false,"allow401Images":false},"html":{"imageMaxWidth":1024,"useGatsbyImage":true,"fallbackImageMaxWidth":1024,"imageQuality":70,"createStaticFiles":true,"generateWebpImages":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"type":{"Post":{"limit":9999},"Page":{"exclude":true},"Menu":{"exclude":true},"Taxonomy":{"exclude":true},"Comment":{"exclude":true},"MenuItem":{"exclude":true},"ContentType":{"exclude":true},"Tag":{"exclude":true},"UserRole":{"exclude":true},"PostFormat":{"exclude":true},"MediaItem":{"localFile":{"requestConcurrency":50,"excludeByMimeTypes":[],"maxFileSizeBytes":15728640},"placeholderSizeName":"gatsby-image-placeholder","createFileNodes":true,"lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"modelPages":["Post"],"schema":{"timeout":120000,"perPage":50,"requestConcurrency":50,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","previewRequestConcurrency":5},"supports":{"wpml":false,"acf":false,"yoast":true,"rankmath":false},"catchLinks":true},
    },{
      plugin: require('../../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1280,"quality":90,"withWebp":true,"srcSetBreakpoints":[576],"linkImagesToOriginal":false,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../../../packages/gatsby-theme-soluble-source/gatsby-browser.js'),
      options: {"plugins":[],"primaryLanguage":"es","language":{"es":""},"createLocalizedNotFound":false,"pathsByLanguage":{"post":{"es":"recursos"}},"local":false,"wordpressGraphql":{"url":"https://blog.dozeninvestments.com/graphql","auth":{"htaccess":{"username":"dozen","password":"dozen"}},"verbose":true,"develop":{"hardCacheMediaFiles":true,"nodeUpdateInterval":2000000},"production":{"hardCacheMediaFiles":true},"html":{"imageMaxWidth":1024},"type":{"Post":{"limit":9999},"Page":{"exclude":true},"Menu":{"exclude":true},"Taxonomy":{"exclude":true},"Comment":{"exclude":true},"MenuItem":{"exclude":true},"ContentType":{"exclude":true},"Tag":{"exclude":true},"UserRole":{"exclude":true},"PostFormat":{"exclude":true},"MediaItem":{"localFile":{"requestConcurrency":50}}},"modelPages":["Post"],"schema":{"timeout":120000,"perPage":50,"requestConcurrency":50},"supports":{"wpml":false,"acf":false,"yoast":true,"rankmath":false}},"datocms":{"modelPages":["Page"],"previewMode":false,"environment":""}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"dozen","short_name":"dozen","start_url":"/","background_color":"#ffffff","theme_color":"#009877","display":"minimal-ui","icon":"/opt/build/repo/packages/design-system/src/favicon/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0dbb6b457cf96cfcae39253088f5d9e7"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"includeInDevelopment":true,"id":"GTM-WTC5B2F","gtmAuth":"ndra5c8IC7xF5w87Dw9SEQ","gtmPreview":"env-1","defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
